import { useState } from 'react'

import { Box } from '@mui/material'

import EcSelect from '@components/inputs/EcSelect'
import { SpaceHorizontal } from '@utils/Spacing'
import BarSimple from '@components/charts/BarSimple'
// import ToolBarChart from './Common/ToolBarChart'

interface IDataItem {
  year: string
  hl7Number: number
}

const dataReceived: IDataItem[] = [
  { year: '2017', hl7Number: 10 },
  { year: '2018', hl7Number: 4 },
  { year: '2019', hl7Number: 5 },
  { year: '2020', hl7Number: 15 },
  { year: '2021', hl7Number: 5 },
  { year: '2022', hl7Number: 8 },
  { year: '2023', hl7Number: 6 },
]

const dataSent: IDataItem[] = [
  { year: '2010', hl7Number: 1 },
  { year: '2011', hl7Number: 17 },
  { year: '2012', hl7Number: 2 },
  { year: '2013', hl7Number: 5 },
  { year: '2014', hl7Number: 12 },
  { year: '2015', hl7Number: 7 },
  { year: '2016', hl7Number: 3 },
]

// export const dataReceived = [{
//   date: '2012',
//   received: 6,
//   sent: 10,
// }, {
//   date: '2013',
//   received: 18,
//   sent: 30,
// }, {
//   date: '2015',
//   received: 9,
//   sent: 15,
// }, {
//   date: '2020',
//   received: 17,
//   sent: 19,
// }];

// const useStyles = makeStyles((theme) => ({
// }))

export default function HL7Chart() {
  // const classes = useStyles()
  // const [dataChart, setDataChart] = React.useState<Array<any>>(dataReceived);

  const [currentChart, setCurrentChart] = useState('1')

  const ToolBar = () => (
    <Box mb={1.5} display="flex">
      <EcSelect
        label="HL7"
        options={[
          { value: '1', label: 'received' },
          { value: '2', label: 'sent' },
        ]}
        handleSelected={(option: string) => {
          setCurrentChart(option)
          // console.log(option);
          // setDataChart(option === '1' ? dataReceived : dataSent)
        }}
      />
      <SpaceHorizontal />
      <EcSelect
        label="Type Hl7"
        options={[
          { value: 1, label: 'ADT' },
          { value: 2, label: 'ORM' },
          { value: 3, label: 'ORU' },
          { value: 4, label: 'A40-A18' },
        ]}
      />
      <SpaceHorizontal />
      <EcSelect
        label="Period"
        options={[
          { value: 1, label: 'Day' },
          { value: 2, label: 'Week' },
          { value: 3, label: 'Month' },
          { value: 4, label: 'Start/End' },
        ]}
      />
    </Box>
  )

  return (
    <BarSimple
      data={currentChart === '1' ? dataReceived : dataSent}
      title="HL7 receved"
      valueField="hl7Number"
      argumentField="year"
      // height={370}
      name={currentChart === '1' ? 'Hl7 Received' : 'Hl7 Sent'}
      color={currentChart === '1' ? '' : 'orange'}
      toolBar={ToolBar}
    />
  )
}
