import { useState } from 'react'

import { Box } from '@mui/material'

import EcSelect from '@components/inputs/EcSelect'
import { SpaceHorizontal } from '@utils/Spacing'
import LineSimple from '@components/charts/LineSimple'
import { chartPerformanceSelect } from '@utils/constants'
import Pie from '@components/charts/Pie'
import BarSimple from '@components/charts/BarSimple'

interface IDataItem {
  year: string
  hl7Number: number
}

const dataReceived: IDataItem[] = [
  { year: '2017', hl7Number: 10 },
  { year: '2018', hl7Number: 4 },
  { year: '2019', hl7Number: 5 },
  { year: '2020', hl7Number: 15 },
  { year: '2021', hl7Number: 5 },
  { year: '2022', hl7Number: 8 },
  { year: '2023', hl7Number: 6 },
]

// const dataSent: IDataItem[] = [
//   { year: '2010', hl7Number: 1 },
//   { year: '2011', hl7Number: 17 },
//   { year: '2012', hl7Number: 2 },
//   { year: '2013', hl7Number: 5 },
//   { year: '2014', hl7Number: 12 },
//   { year: '2015', hl7Number: 7 },
//   { year: '2016', hl7Number: 3 },
// ]

// export const dataReceived = [{
//   date: '2012',
//   received: 6,
//   sent: 10,
// }, {
//   date: '2013',
//   received: 18,
//   sent: 30,
// }, {
//   date: '2015',
//   received: 9,
//   sent: 15,
// }, {
//   date: '2020',
//   received: 17,
//   sent: 19,
// }];

// const useStyles = makeStyles((theme) => ({

// }))

// const fields = [
//   {
//     name: 'hl7 Received',
//     valueField: 'received',
//     argumentField: 'date',
//   },

//   {
//     name: 'hl7 Sent',
//     valueField: 'sent',
//     argumentField: 'date',
//   },
// ]
function getLabelWithValue(value: unknown, ArraySelect: Array<any>): string {
  return ArraySelect[(value as number) - 1].label
}

export default function PerformanceChart() {
  // const classes = useStyles()

  // const [dataChart, setDataChart] = React.useState<Array<any>>(dataReceived)
  const [typeChart, setTypeChart] = useState('1')

  const ToolBar = () => (
    <Box mb={1.5} display="flex">
      <EcSelect
        label="Chart"
        options={chartPerformanceSelect}
        handleSelected={(option: string) => {
          setTypeChart(option)
        }}
      />
      {['1', '2', '3', '4', '5', '6'].includes(typeChart) ? (
        <>
          <SpaceHorizontal />
          <EcSelect
            label="Type"
            options={[
              { value: 1, label: 'Radiology' },
              { value: 2, label: 'CT' },
              { value: 3, label: 'MR' },
            ]}
          />
        </>
      ) : typeChart === '8' ? (
        <>
          <SpaceHorizontal />
          <EcSelect
            label="Type studies"
            options={[
              { value: 1, label: 'CR-DR-DX' },
              { value: 2, label: 'CT over' },
              { value: 3, label: 'MR over' },
            ]}
          />
        </>
      ) : null}

      <SpaceHorizontal />
      <EcSelect
        label="Period"
        options={[
          { value: 1, label: 'Day' },
          { value: 2, label: 'Week' },
          { value: 3, label: 'Month' },
          { value: 4, label: 'Start/End' },
        ]}
      />
    </Box>
  )

  return (
    <>
      {['1', '2', '3', '4', '5', '6'].includes(typeChart) ? (
        <LineSimple
          data={dataReceived}
          title="HL7 receved"
          valueField="hl7Number"
          argumentField="year"
          // height={350}
          toolBar={ToolBar}
        />
      ) : typeChart === '7' ? (
        <BarSimple
          data={dataReceived}
          valueField="hl7Number"
          argumentField="year"
          // height={350}
          name={
            getLabelWithValue(typeChart, chartPerformanceSelect).toString() ||
            ''
          }
          toolBar={ToolBar}
        />
      ) : typeChart === '8' ? (
        <Pie
          data={dataReceived}
          valueField="hl7Number"
          argumentField="year"
          // height={320}
          toolBar={ToolBar}
        />
      ) : null}
    </>
  )
}
