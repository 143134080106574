import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AccountDto, LoginResDto, SuperAdmin, Member } from '@services/api'
import StorageService from '@services/storage'
import { findMyInfos, getCaptcha } from '@state/thunks/authThunk'

// export type Account = PatientDto | DoctorDto | AdminDto | SuperAdminDto
type authState = {
  captcha?: string
  isAuth: boolean
  account?: AccountDto
  user?: Member | SuperAdmin
  language: string
  signFail: {
    failure: number
    period: number
    window: number
    timeout: number
  }
}

const initialState: authState = {
  isAuth: false,
  language: navigator.language.split(/[-_]/)[0],
  signFail: {
    failure: 0,
    period: 0,
    window: 0,
    timeout: 0,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginFulfilled: (state, { payload }: PayloadAction<LoginResDto>) => {
      StorageService.setAuthToken(payload.token as string)
      state.account = payload.user
      state.isAuth = true
    },
    updateAccount: (state, { payload }: PayloadAction<any>) => {
      state.account = { ...state.account, ...payload }
    },
    // updateDisplayPreferences: (state, { payload }: PayloadAction<any>) => {
    //   if (state.user) state.user.preferences.display.home = payload
    // },
    // updateHiddenColumns: (state, { payload }: PayloadAction<any>) => {
    //   if (state.user)
    //     state.user.preferences.display.home.hiddenColumns = payload
    // },
    // updateColumnOrder: (state, { payload }: PayloadAction<any>) => {
    //   if (state.user) state.user.preferences.display.home.columnOrder = payload
    // },
    // updateColumnWidths: (state, { payload }: PayloadAction<any>) => {
    //   if (state.user) state.user.preferences.display.home.columnWidth = payload
    // },
    // setProfile: (state, { payload }: PayloadAction<Account>) => {
    //   state.profile = payload
    // },
    setLanguage: (state, { payload }: PayloadAction<string>) => {
      state.language = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCaptcha.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.captcha = payload.captcha
        },
      )
      .addCase(
        findMyInfos.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.user = payload
        },
      )
  },
})

export const {
  loginFulfilled,
  setLanguage,
  updateAccount,
  // updateHiddenColumns,
  // updateColumnOrder,
  // updateColumnWidths,
} = authSlice.actions

export default authSlice.reducer
