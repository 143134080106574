import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Paper,
  Container,
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
} from '@mui/material'
import { DataTable } from '@components/tables'
import { removeMembers } from '@state/reducers/memberReducer'
import { findAll as findAllClient } from '@state/thunks/clientThunk'
import { findAll as findAllSites } from '@state/thunks/siteThunk'
import {
  deleteMember,
  findAll as findAllMember,
} from '@state/thunks/memberThunk'
import { removeClients } from '@state/reducers/clientReducer'
import { removeSites } from '@state/reducers/siteReducer'
import { DialogTitle } from '@components/titles'
import { MemberForm } from './MemberForm'
import { useSelector } from '@state/store'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { Actions } from '@state/actions'
import theme from '@utils/theme'
import { Button } from '../../../components/buttons'
import Text from '@components/Text'

export default function Members() {
  const dispatch = useDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const { client, members, loading } = useSelector(
    ({ member, auth, loading }) => ({
      members: member.members.datas,
      client: auth.account?.client,
      loading:
        loading[Actions.MEMBER_FIND_ALL] ||
        loading[Actions.MEMBER_CREATE] ||
        loading[Actions.MEMBER_UPDATE],
    }),
  )

  const columns: GridColumns = [
    { field: 'firstName', headerName: 'Firstname', flex: 1 },
    { field: 'lastName', headerName: 'Lastname', flex: 1 },
    {
      field: 'memberJob',
      headerName: 'Position',
      flex: 1,
      valueGetter: ({ row }) => row.role,
    },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phoneNumber', headerName: 'Phone', flex: 1 },
    { field: 'address', headerName: 'Address', flex: 1 },
  ]

  const onRefresh = useCallback(() => {
    dispatch(findAllMember({}))
    if (!client) dispatch(findAllClient())
    else dispatch(findAllSites())
  }, [client, dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeMembers())
      dispatch(removeClients())
      dispatch(removeSites())
    }
  }, [dispatch, onRefresh])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }
  const _onDeleteConfirm = () => {
    dispatch(deleteMember(selectedRows))
    setIsDeleteDialogOpen(false)
  }

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '90vh', marginTop: '25px' }}>
        <Grid item container>
          <Paper elevation={2} style={{ width: '100%', borderRadius: 25 }}>
            <DataTable
              rows={members}
              columns={columns}
              loading={loading}
              toolBarProps={{
                title: 'members',
                icon: 'fa fa-hospital-user',
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
                onDelete: () => {
                  selectedRows.length
                    ? setIsDeleteDialogOpen(true)
                    : alert('please select at least one room')
                },
              }}
              detailPanelHeight={400}
              form={(props) => (
                <MemberForm
                  {...props}
                  onClose={() => setOpenAddDialog(false)}
                />
              )}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
            />
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            minWidth: '50%',
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addMember"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent sx={{ background: '#fff', padding: '50px' }}>
          <MemberForm onClose={() => setOpenAddDialog(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="deleteReception"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
    </Container>
  )
}
