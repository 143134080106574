import { useState } from 'react'

import { Box } from '@mui/material'

import { SpaceHorizontal } from '@utils/Spacing'
import EcSelect from '@components/inputs/EcSelect'
import BarSimple from '@components/charts/BarSimple'

interface IDataItem {
  year: string
  dicomNumber: number
}

const dataReceived: IDataItem[] = [
  { year: '2017', dicomNumber: 8 },
  { year: '2018', dicomNumber: 14 },
  { year: '2019', dicomNumber: 3 },
  { year: '2020', dicomNumber: 10 },
  { year: '2021', dicomNumber: 7 },
  { year: '2022', dicomNumber: 8 },
  { year: '2023', dicomNumber: 9 },
]

const dataSent: IDataItem[] = [
  { year: '2017', dicomNumber: 2 },
  { year: '2018', dicomNumber: 4 },
  { year: '2019', dicomNumber: 6 },
  { year: '2020', dicomNumber: 8 },
  { year: '2021', dicomNumber: 9 },
  { year: '2022', dicomNumber: 21 },
  { year: '2023', dicomNumber: 15 },
]

// const useStyles = makeStyles((theme) => ({

// }))

// const fields = [
//   {
//     name: "Dicom Received",
//     valueField: "received",
//     argumentField: "date",
//   },

//   {
//     name: "Dicom Sent",
//     valueField: "sent",
//     argumentField: "date",
//   }
// ]

export default function DicomChart() {
  // const classes = useStyles()
  const [currentChart, setCurrentChart] = useState('1')

  const ToolBar = () => (
    <Box mb={1.5} display="flex">
      <EcSelect
        label="Dicom"
        options={[
          { value: '1', label: 'received' },
          { value: '2', label: 'sent' },
        ]}
        handleSelected={(option: string) => {
          setCurrentChart(option)
        }}
      />
      <SpaceHorizontal />
      <EcSelect
        label="Type Dicom"
        options={[
          { value: 1, label: 'CT' },
          { value: 2, label: 'MR' },
          { value: 3, label: 'US' },
          // { value: 4, label: 'A40-A18' }
        ]}
      />
      <SpaceHorizontal />
      <EcSelect
        label="Period"
        options={[
          { value: 1, label: 'Day' },
          { value: 2, label: 'Week' },
          { value: 3, label: 'Month' },
          { value: 4, label: 'Start/End' },
        ]}
      />
    </Box>
  )

  return (
    <BarSimple
      data={currentChart === '1' ? dataReceived : dataSent}
      valueField="dicomNumber"
      argumentField="year"
      // height={350}
      name={currentChart === '1' ? 'Dicom Received' : 'Dicom Sent'}
      color={currentChart === '1' ? '' : 'orange'}
      toolBar={ToolBar}
    />
  )
}
