import {
  AsyncThunk,
  createSlice,
  isFulfilled,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

type Variant = 'error' | 'info' | 'default' | 'warning' | 'success'

type Snackbar = {
  message: string
  key: number
  options: {
    key: number
    variant?: Variant
    // action: (key: number) => JSX.Element
  }
  dismissed?: boolean
  displayed?: boolean
}

type AlertState = {
  notifications: Snackbar[]
}

const initialState: AlertState = {
  notifications: [],
}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    enqueueSnackbar: {
      reducer: (state, { payload }: PayloadAction<Snackbar>) => {
        state.notifications.push(payload)
      },
      prepare({ message, type }) {
        const payload: Snackbar = {
          key: new Date().getTime() + Math.random(),
          message, //TODO || intl.formatMessage({ id: message }),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: type,
          },
        }
        return { payload }
      },
    },
    removeSnackbar(state, { payload }: PayloadAction<number>) {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== payload,
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher<FulfilledAction>(isFulfilled, (state, { payload }) => {
        if (typeof payload === 'string') {
          const notification: Snackbar = {
            key: new Date().getTime() + Math.random(),
            message: payload as string,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }
          state.notifications.push(notification)
        }
      })
      .addMatcher<RejectedAction>(isRejected, (state, { payload }) => {
        const notification: Snackbar = {
          key: new Date().getTime() + Math.random(),
          message: payload as string, //TODO || intl.formatMessage({ id: message }),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }
        state.notifications.push(notification)
      })
  },
})

export const { enqueueSnackbar, removeSnackbar } =
  alertSlice.actions
export default alertSlice.reducer
