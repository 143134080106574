import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  Paper,
  DialogActions,
  Icon,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import { DataTable } from '@components/tables'
import {
  deleteExam,
  findAll as findAllExam,
  findAllType,
  findInstructions,
} from '@state/thunks/procedureThunk'
import { ProcedureForm } from './ProcedureForm'
import { useSelector } from '@state/store'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { DialogTitle } from '@components/titles'
import { ChipCell } from '@components/tables/cells'
import { Actions } from '@state/actions'
import theme from '@utils/theme'
import { Button } from '../../../components/buttons'
import { Text } from '../../../components/texts'
import { Exam, ExamType, Instruction, QuestionDto } from '@services/api'
import { findAllQuestions } from '../../../state/thunks/questionThunk'
import { Spinner } from '../../../components/loadings'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function Procedures() {
  const dispatch = useDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event, examId: number) => {
    setAnchorEl(event.currentTarget)
    dispatch(findAllQuestions(examId))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { loading, exams,  } = useSelector(
    ({ procedure, loading }) => ({
      exams: procedure.exams.datas,
      examType: procedure.examTypes,
      loading:
        loading[Actions.PROCEDURE_FIND_ALL] ||
        loading[Actions.PROCEDURE_CREATE] ||
        loading[Actions.PROCEDURE_UPDATE],
    }),
  )

  const { loading: questionLoading, questions } = useSelector(
    ({ question, loading }) => ({
      questions: question.questions,
      loading:
        loading[Actions.QUESTION_FIND_ALL] ||
        loading[Actions.QUESTION_FIND_ALL],
    }),
  )

  const columns2: GridColumns = [
    {
      field: '',
      headerName: 'Questions',
      flex: 1,
      renderCell: (value) => (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
          onClick={(ev) => handleClick(ev, value.id as number)}
        >
          <Icon className="fas fa-clipboard-list" />
        </div>
      ),
    },
    { field: 'code', headerName: 'Code', flex: 1 },
    { field: 'label', headerName: 'Label', flex: 1 },
    {
      field: 'examTypes',
      headerName: 'Type',
      flex: 1,
      valueGetter: ({ value }: { value: ExamType[] }) => {
        let text = ''

        value.forEach((type, index) => {
          if (index > 0) {
            text += ', '
          }
          text += type.label
        })

        return text
      },
    },
    { field: 'modality', headerName: 'Modality', flex: 1 },
    {
      field: 'duration',
      headerName: 'Time',
      flex: 1,
    },
    {
      field: 'instructionsList',
      headerName: 'Instructions',
      flex: 1,
      valueGetter: ({ value }: { value: Instruction[] }) => {
        let text = ''

        value.forEach((v, index) => {
          if (index > 0) {
            text += ', '
          }
          text += v.label
        })

        return text
      },
    },
    { field: 'sex', headerName: 'Sex', flex: 1 },
    {
      field: 'bodyPart',
      headerName: 'Body part',
      flex: 1,
      renderCell: ChipCell,
    },
    { field: 'ageRange', headerName: 'Age range', flex: 1 },
    {
      field: 'contrast',
      headerName: 'Contrast',
      flex: 1,
      type: 'boolean',
    },
    { field: 'dose', headerName: 'Dose', flex: 1, type: 'boolean' },
  ]
  const onRefresh = useCallback(() => {
    dispatch(findAllType())
    dispatch(findAllExam({}))
    dispatch(findInstructions())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
  }, [dispatch, onRefresh])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }
  const _onDeleteConfirm = () => {
    dispatch(deleteExam(selectedRows))
    setIsDeleteDialogOpen(false)
  }
  const _renderQuestion = (question: QuestionDto) => {
    return (
      <Grid container sx={{ padding: '5px' }}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ background: '#009da010' }}
            >
              {question.text}
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: 16, paddingRight: 16 }}>
              <Typography variant="body1">Choix:</Typography>
              {question.choices.length !== 0 ? (
                question.choices.map((c) => (
                  <div key={c.id}>
                    <Typography variant="caption" style={{ marginLeft: 8 }}>
                      - {c.text}
                    </Typography>
                  </div>
                ))
              ) : (
                <>
                  <div>
                    <Typography variant="caption" style={{ marginLeft: 8 }}>
                      - Oui
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption" style={{ marginLeft: 8 }}>
                      - Non
                    </Typography>
                  </div>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    )
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '95vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{ width: '100%', borderRadius: '0px 25px 25px 25px' }}
          >
            <DataTable
              rows={exams}
              columns={columns2}
              loading={loading}
              toolBarProps={{
                title: 'procedures',
                icon: 'fa fa-syringe',
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
                onDelete: () => {
                  selectedRows.length
                    ? setIsDeleteDialogOpen(true)
                    : alert('please select at least one reception')
                },
              }}
              detailPanelHeight={400}
              form={(
                props: JSX.IntrinsicAttributes & {
                  row?: Exam | undefined
                  onClose?: (() => void) | undefined
                },
              ) => (
                <ProcedureForm
                  {...props}
                  onClose={() => setOpenAddDialog(false)}
                />
              )}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
            />
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            borderRadius: 25,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addExam"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent sx={{ background: '#fff', padding: '50px' }}>
          <ProcedureForm
            onClose={() => setOpenAddDialog(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="deleteReception"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {questionLoading ? (
          <Spinner />
        ) : (
          questions.map((q) => _renderQuestion(q))
        )}
      </Popover>
    </Container>
  )
}
