/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Paper } from '@mui/material'
import { SimpleTable } from '@components/tables'
import { findAll as findAllMember } from '@state/thunks/memberThunk'
import { useSelector } from '@state/store'
import { Actions } from '@state/actions'
import { removeSites } from '@state/reducers/siteReducer'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import _ from 'lodash'

export interface RoleSelectionTableProps {
  onChange: (values: number[]) => void
  selectedRows: number[] | string[]
}

export default function RoleSelectionTable(props: RoleSelectionTableProps) {
  const { onChange, selectedRows } = props
  const [search, setSearch] = useState('')

  const dispatch = useDispatch()

  const _onSelectionChange = (selection: GridRowId[]) => {
    onChange(selection as number[])
  }

  const { loading, members } = useSelector(({ member, loading }) => ({
    members: member.members.datas,
    loading:
      loading[Actions.MEMBER_FIND_ALL] ||
      loading[Actions.MEMBER_CREATE] ||
      loading[Actions.MEMBER_UPDATE],
  }))

  const debounceRef = useRef<_.DebouncedFunc<any> | null>(null)

  const columns: GridColumns = [
    { field: 'firstName', headerName: 'Firstname', flex: 1 },
    { field: 'lastName', headerName: 'Lastname', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phoneNumber', headerName: 'Phone', flex: 1 },
    {
      field: 'memberJob',
      headerName: 'Position',
      flex: 1,
      valueGetter: ({ row }) => row.role,
    },
  ]
  const onRefresh = useCallback(() => {
    dispatch(findAllMember({ search }))
  }, [dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeSites())
    }
  }, [dispatch, onRefresh])

  const handleMemberSearch = (value: string) => {
    const searchMemberDebounce = _.debounce(
      () => dispatch(findAllMember({ search: value })),
      350,
    )
    searchMemberDebounce()
    if (debounceRef.current) {
      debounceRef.current.cancel()
    }
    debounceRef.current = searchMemberDebounce
  }

  return (
    <Grid container style={{ height: '50vh' }}>
      <Paper
        elevation={1}
        style={{
          width: '100%',
          border: '1px solid rgba(0, 0, 0, 0.125)',
          backgroundColor: '#eef1f6',
        }}
      >
        <SimpleTable
          rows={members}
          columns={columns}
          loading={loading}
          simpleToolBarProps={{
            title: 'assignedMember',
            icon: 'fa fa-link',
            search: true,
            onSearch: handleMemberSearch,
          }}
          checkboxSelection
          onSelectionModelChange={_onSelectionChange}
          selections={selectedRows}
        />
      </Paper>
    </Grid>
  )
}
