import React, { useState } from 'react'
import { Grid, Paper } from '@mui/material'
import { EhBasicTable } from '@components/tables'
import { Button } from '@components/buttons'
import AddIcon from '@mui/icons-material/Add'
import { EditAddTermsTemplateDialog } from './EditAddTermsTemplateDialog'

type TemplateScreenProps = {
  terms: {
    id: number
    status: string
    effectiveDate: string
    timezone: string
    terms: string
    changeSummary: string
    content: string
  }[]
  newTerm: {
    id: number
    status: string
    effectiveDate: string
    timezone: string
    terms: string
    changeSummary: string
    content: string
  }
}

export function TermTemplate({ terms }: TemplateScreenProps) {
  const [openDialog, setOpenDialog] = useState(false)
  const [currentRow, setCurrentRow] = React.useState<object | null>()

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: 2,
          borderRadius: 5,
        }}
      >
        <Grid item xs={12}>
          <Button
            text="addTemplate"
            color="primary"
            startIcon={<AddIcon sx={{ color: 'white' }} />}
            textColor="white"
            onClick={() => {
              console.log('addTemplate')
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <EhBasicTable
              size="medium"
              columns={columns}
              datas={terms}
              actions={[
                {
                  icon: 'far fa-clone',
                  text: 'Duplicate',
                  onClick: () => {
                    console.log('Duplicate')
                  },
                },
                {
                  icon: 'fas fa-pencil-alt',
                  text: 'Edit',
                  onClick: (row) => {
                    setOpenDialog(true)
                    setCurrentRow(row)
                  },
                },
                {
                  icon: 'far fa-trash-alt',
                  text: 'Delete',
                  onClick: () => {
                    console.log('Delete')
                  },
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>

      {openDialog && (
        <EditAddTermsTemplateDialog
          field={currentRow}
          open={openDialog}
          onClose={() => {
            setOpenDialog(false)
          }}
        />
      )}
    </>
  )
}

const columns = [
  { name: 'Status', field: 'status' },
  { name: 'Effectivre Date', field: 'effectiveDate' },
  { name: 'Time Zone', field: 'timezone' },
  { name: 'Terms', field: 'terms' },
  { name: 'Change Summary', field: 'changeSummary' },
  {
    name: 'Action',
    field: 'action',
    columnAlign: 'left',
  },
]
