import {
  DataGridPro,
  LicenseInfo,
  useGridApiRef,
  GridColumns,
  GridRowsProp,
  GridInitialState,
  GridEventListener,
  GridEvents,
  GridRowParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridSelectionModel,
  GridRowIdGetter,
} from '@mui/x-data-grid-pro'
import { useCallback } from 'react'
import {
  Toolbar,
  Pagination,
  NoRowsOverlay,
  LoadingOverlay,
  ToolbarProps,
  DetailPanelToggle,
} from './components'
import { Box } from '@mui/material'

type DataTableProps = {
  loading: boolean
  rows: GridRowsProp
  columns: GridColumns
  toolBarProps: ToolbarProps
  initialState?: GridInitialState
  onRowClick?: GridEventListener<GridEvents.rowClick>
  form?: any
  detailPanelHeight: number
  checkboxSelection?: boolean
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void
  getRowId?: GridRowIdGetter
}

export function DataTable({ form: Form, ...props }: DataTableProps) {
  const apiRef = useGridApiRef()
  LicenseInfo.setLicenseKey(
    'x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e',
  )
  const getDetailPanelHeight = useCallback(() => props.detailPanelHeight, [])
  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams<any>) => (
      <Box sx={{ p: '10px', background: '#fff' }}>
        <Form row={row} />
      </Box>
    ),
    [],
  )
  return (
    <DataGridPro
      {...props}
      columns={[
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          renderCell: (params) => (
            <DetailPanelToggle id={params.id} value={params.value} />
          ),
        },
        ...props.columns,
      ]}
      apiRef={apiRef}
      pageSize={5}
      rowHeight={60}
      rowsPerPageOptions={[5]}
      disableColumnMenu
      disableSelectionOnClick
      components={{
        Toolbar,
        Pagination,
        NoRowsOverlay,
        LoadingOverlay,
      }}
      componentsProps={{
        toolbar: props.toolBarProps,
        row: {
          //   onclick: handleExpand,
          style: { cursor: 'pointer' },
        },
      }}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      sx={{
        border: 'none',
        padding: '5px 15px',
      }}
    />
  )
}
