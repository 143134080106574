import { Icon, styled } from '@mui/material'
import Chip from '@mui/material/Chip'
import { GridCellParams } from '@mui/x-data-grid-pro'

const StyledChip = styled(Chip)(() => ({
  justifyContent: 'left',
  paddingLeft: 10,
  '& .icon': {
    color: 'inherit',
  },
}))

export const SexCell = ({ value }: GridCellParams) => {
  let icon: any = null
  console.log('Sex', value)
  if (value === 'U') {
    icon = <Icon className="fa fa-venus-mars" style={{ color: 'black' }} />
  } else if (value === 'M') {
    icon = <Icon className="fa fa-mars" style={{ color: 'black' }} />
  } else if (value === 'F') {
    icon = <Icon className="fa fa-venus" style={{ color: 'black' }} />
  } else {
    icon = <Icon className="fa fa-genderless" style={{ color: 'black' }} />
  }

  return <StyledChip size="small" icon={icon} variant="outlined" />
}
