import { MultipleSelectInput, SelectInput, TextInput } from '@components/inputs'
import { Divider, Grid } from '@mui/material'
import { useSelector } from '@state/store'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@components/buttons'
import { SpaceVertical } from '@utils/Spacing'
import { useDispatch } from 'react-redux'
import { findAll as findAllSites } from '@state/thunks/siteThunk'
// import { addRoleSchema } from '@utils/schemas'
import RoleSelectionTable from './RoleSelectionTable'
import {
  AccessEnum,
  CreateRoleDto,
  RoleDto,
  UpdateRoleDto,
} from '@services/api'
import { createRole, updateRole } from '../../../state/thunks/roleThunk'
import { addRoleSchema } from '@utils/schemas'
import { yupResolver } from '@hookform/resolvers/yup'

type AddRoleFormProps = {
  row?: RoleDto
  onClose?: () => void
}

export function RoleForm({ row, onClose }: AddRoleFormProps) {
  const dispatch = useDispatch()
  const [selectedPermissions, setSelectedPermissions] = useState<AccessEnum[]>(
    [],
  )
  const [selectedMembers, setSelectedMembers] = useState<number[]>([])

  // Form control
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<RoleDto>({
    resolver: yupResolver(addRoleSchema),
    defaultValues: {
      ...row,
    },
  })
  const onSubmit = (values: RoleDto) => {
    if (row?.id) {
      const updateRoleDto: UpdateRoleDto = {
        ...row,
        name: values.name,
        description: values.description,
        permissions: selectedPermissions,
        siteId: values.siteId,
        members: selectedMembers.map((_m) => ({ id: _m })),
      }
      dispatch(updateRole({ id: row.id, body: updateRoleDto }))
    } else {
      const createRoleDto: CreateRoleDto = {
        name: values.name,
        description: values.description,
        permissions: selectedPermissions,
        siteId: values.siteId,
        members: selectedMembers.map((_m) => ({ id: _m })),
      }
      dispatch(createRole(createRoleDto))
    }
    if (onClose) {
      onClose()
    }
  }

  // Reducers
  const { sites } = useSelector(({ site }) => ({
    sites: site.sites.datas,
  }))
  // Map all sites
  const sitesOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id as number,
        label: site.label,
      })),
    [sites],
  )
  useEffect(() => {
    if (row) {
      setSelectedMembers(row.members.map((member) => member.id))
      setSelectedPermissions(row.permissions.map((p) => p.permission))
    }
  }, [row])

  useEffect(() => {
    dispatch(findAllSites())
  }, [])

  const permissionOptions = Object.values(AccessEnum).map((_v) => ({
    label: _v,
    value: _v,
  }))

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any

    if (el) {
      el.click()
    }
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid item xs={12} xl={6}>
        <SelectInput
          name="siteId"
          control={control}
          options={sitesOptions}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput
          variant="outlined"
          name="name"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          variant="outlined"
          name="description"
          errors={errors}
          control={control}
        />
      </Grid>

      <Grid item xs={12}>
        <MultipleSelectInput
          name="permissions"
          label="Permissions"
          options={permissionOptions}
          variant="outlined"
          onChange={(ev: any) => setSelectedPermissions(ev.target.value)}
          value={selectedPermissions}
        />
      </Grid>

      <Grid item xs={12}>
        <SpaceVertical size={5} />
        <Divider variant="middle" />
        <SpaceVertical size={5} />
      </Grid>

      <Grid item xs={12}>
        <RoleSelectionTable
          onChange={setSelectedMembers}
          selectedRows={selectedMembers}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={2}>
            <Button
              onClick={handleCancelPress}
              color="secondary"
              text="Cancel"
              textColor="white"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="Save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
