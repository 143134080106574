import { BarSeries, Legend } from '@devexpress/dx-react-chart-material-ui'
import { ArgumentAxis, ValueAxis } from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'

import BaseChart from './BaseChart'

type BarSimpleProps = {
  title?: string
  data: Array<any>
  valueField: string
  argumentField: string
  color?: string
  name?: string
  toolBar?: any
}

export default function BarSimple({
  data,
  valueField,
  argumentField,
  color,
  name,
  toolBar,
}: BarSimpleProps) {
  return (
    <BaseChart data={data}>
      <ArgumentAxis />
      <ValueAxis />
      <BarSeries
        valueField={valueField}
        argumentField={argumentField}
        color={color}
        name={name}
      />
      <Animation />
      <Legend
        position="top"
        rootComponent={toolBar}
        // itemComponent={LegendItemRoot}
        // labelComponent={LegendLabelRoot}
        // markerComponent={Marker}
      />
    </BaseChart>
  )
}
