import { useIntl } from 'react-intl'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'

import { TextField, Autocomplete } from '@mui/material'

interface option {
  value: string | number
  label: string
}

type ControlledAutocompleteProps = {
  errors?: DeepMap<any, FieldError>
  input: string
  options: option[]
  control: Control<any>
  defaultValue?: unknown
}

export function ControlledAutocompleteInput({
  errors,
  input,
  options,
  control,
  defaultValue,
}: ControlledAutocompleteProps) {
  const intl = useIntl()
  return (
    <Controller
      name={input}
      control={control}
      defaultValue={defaultValue}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field:{onChange, value, ...props} }) => {
        return (
          <Autocomplete
            {...props}
            fullWidth
            options={options}
            getOptionLabel={(option) => (option ? option.label : '')}
            // getOptionSelected={(option, value) => option.value === value.value}
            onChange={(_, value) => onChange(value ? value.value : '')}
            // value={{
            //   // make sure we remain the corect format for the controlled component
            //   value: value,
            //   label: value
            // }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: {
                    color: '#464855',
                  },
                }}
                name={input}
                label={
                  input && intl.formatMessage({ id: input })
                }
                placeholder={
                  input &&
                  intl.formatMessage({ id: input })
                }
                error={errors && !!errors[input]}
                helperText={
                  errors &&
                  errors[input] &&
                  intl.formatMessage({ id: errors[input].message })
                }
              />
            )}
          />
        )
      }}
    />
  )
}
