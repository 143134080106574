import {
  Button as ButtonBase,
  ButtonProps as ButtonBaseProps,
  CircularProgress,
} from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { Text } from '../texts'

export type ButtonProps = {
  text: string
  loading?: boolean
  link?: string
  textColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'initial'
    | 'error'
    | 'white'
  format?: boolean
} & ButtonBaseProps

export function Button({
  text,
  loading,
  link,
  textColor = 'textSecondary',
  format = true,
  ...props
}: ButtonProps) {
  const navigate = useNavigate()
  return (
    <ButtonBase
      {...props}
      variant={props.variant ? props.variant : !link ? 'contained' : undefined}
      disabled={props.disabled || loading}
      startIcon={loading ? <CircularProgress size={20} /> : props.startIcon}
      onClick={link ? () => navigate(link) : props.onClick}
      style={{ padding: 10, borderRadius: 5 }}
    >
      <Text text={text} color={textColor} format={format} />
    </ButtonBase>
  )
}
