import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Paper } from '@mui/material'
import { SimpleTable } from '@components/tables'
import {
  findAll as findAllExam,
  findAllType,
} from '@state/thunks/procedureThunk'
import { useSelector } from '@state/store'
import { ChipCell } from '@components/tables/cells'
import { Actions } from '@state/actions'
import { removeSites } from '@state/reducers/siteReducer'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import _ from 'lodash'

export interface ExamSelectionTableProps {
  onSelectionChange: (selectedRows: number[] | string[]) => void
  selectedRows: number[] | string[]
}
export const ExamSelectionTable: React.FC<ExamSelectionTableProps> = ({
  onSelectionChange,
  selectedRows,
}) => {
  const dispatch = useDispatch()

  const debounceRef = useRef<_.DebouncedFunc<any> | null>(null)

  const _onSelectionChange = (selection: GridRowId[]) => {
    onSelectionChange(selection as number[] | string[])
  }

  const { loading, exams } = useSelector(({ procedure, loading }) => ({
    exams: procedure.exams.datas,
    examType: procedure.examTypes,
    loading:
      loading[Actions.PROCEDURE_FIND_ALL] ||
      loading[Actions.PROCEDURE_CREATE] ||
      loading[Actions.PROCEDURE_UPDATE],
  }))

  const columns: GridColumns = [
    { field: 'code', headerName: 'Code', flex: 1 },
    { field: 'label', headerName: 'Label', flex: 1 },
    { field: 'sex', headerName: 'Sex', flex: 1 },
    {
      field: 'bodyPart',
      headerName: 'Body part',
      flex: 1,
      renderCell: ChipCell,
    },
    { field: 'ageRange', headerName: 'Age range', flex: 1 },
    {
      field: 'contrast',
      headerName: 'Contrast',
      flex: 1,
      type: 'boolean',
    },
    { field: 'dose', headerName: 'Dose', flex: 1, type: 'boolean' },
  ]
  const onRefresh = useCallback(() => {
    dispatch(findAllType())
    dispatch(findAllExam({}))
  }, [dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeSites())
    }
  }, [dispatch, onRefresh])

  const handleExamSearch = (value: string) => {
    const searchMemberDebounce = _.debounce(
      () => dispatch(findAllExam({ search: value })),
      350,
    )
    searchMemberDebounce()
    if (debounceRef.current) {
      debounceRef.current.cancel()
    }
    debounceRef.current = searchMemberDebounce
  }

  return (
    <Grid container style={{ height: '50vh' }}>
      <Paper
        elevation={1}
        style={{
          width: '100%',
          border: '1px solid rgba(0, 0, 0, 0.125)',
          backgroundColor: '#eef1f6',
        }}
      >
        <SimpleTable
          rows={exams}
          columns={columns}
          loading={loading}
          simpleToolBarProps={{
            title: 'assignedExams',
            icon: 'fa fa-link',
            search: true,
            onSearch: handleExamSearch,
          }}
          checkboxSelection
          onSelectionModelChange={_onSelectionChange}
          selections={selectedRows}
        />
      </Paper>
    </Grid>
  )
}

export default ExamSelectionTable
