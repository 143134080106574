import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: { main: '#66b4b6', contrastText: '#000' },
    secondary: { main: '#c0466f', contrastText: '#000' },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Poppins',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
})
