import { useState } from 'react'
import { Tabs, Tab, Typography, Box, Icon } from '@mui/material'
import { SpaceVertical } from '@utils/Spacing'
import Text from '@components/Text'
import PerformanceChart from './PerformanceChart'
import TelemedecineChart from './TelemedecineChart'
import SecurityChart from './SecurityChart'
import ActivityChart from './ActivityChart'
import DicomChart from './DicomChart'
import HL7Chart from './HL7Chart'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

export default function Stats() {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    console.log(event)
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        maxWidth: '90%',
        margin: 'auto',
        padding: '15px',
        borderRadius: 5,
      }}
    >
      <Box display="flex" alignItems="center">
        <Icon
          className="fa fa-chart-bar"
          sx={{ marginRight: '20px', alignSelf: 'center' }}
          color="primary"
        />
        <Text variant="h4" text="Stats" format color="primary" />
      </Box>

      <SpaceVertical size={15} />

      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        scrollButtons="auto"
        aria-label="stats"
        sx={{ background: '#fff', borderRadius: '5px' }}
      >
        <Tab
          label="HL7"
          {...a11yProps(0)}
          sx={{ background: '#3f3f3f', color: '#fff' }}
        />
        <Tab
          label="Dicom"
          {...a11yProps(1)}
          sx={{ background: '#3f3f3f', color: '#fff' }}
        />

        <Tab
          label="Activity"
          {...a11yProps(2)}
          sx={{ background: '#3f3f3f', color: '#fff' }}
        />
        <Tab
          label="Security"
          {...a11yProps(3)}
          sx={{ background: '#3f3f3f', color: '#fff' }}
        />
        <Tab
          label="Telemedecine"
          {...a11yProps(4)}
          sx={{ background: '#3f3f3f', color: '#fff' }}
        />
        <Tab
          label="Performance & Quality"
          {...a11yProps(5)}
          sx={{ background: '#3f3f3f', color: '#fff' }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <HL7Chart />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DicomChart />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ActivityChart />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SecurityChart />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TelemedecineChart />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <PerformanceChart />
      </TabPanel>
    </Box>
  )
}
