import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MemberDto } from '@services/api'
import {
  createMember,
  deleteMember,
  findAll,
  updateMember,
} from '@state/thunks/memberThunk'

type memberState = {
  members: {
    totalCount: number
    datas: MemberDto[]
  }
  selected?: MemberDto
}

const initialState: memberState = {
  members: {
    totalCount: 0,
    datas: [],
  },
}

const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    removeMembers: (state) => {
      state.members = initialState.members
    },
    setSelected: (state, action: PayloadAction<MemberDto>) => {
      state.selected = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAll.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.members = payload
      })
      .addCase(
        deleteMember.fulfilled,
        (state, { payload }: PayloadAction<number[]>) => {
          state.members.datas = state.members.datas.filter(
            (_member) => !payload.includes(_member.id),
          )
        },
      )
      .addCase(
        createMember.fulfilled.toString(),
        (state, { payload }: PayloadAction<MemberDto>) => {
          state.members.datas = [...state.members.datas, payload]
        },
      )
      .addCase(
        updateMember.fulfilled.toString(),
        (state, { payload }: PayloadAction<MemberDto>) => {
          const index = state.members.datas.findIndex(
            (_member) => _member.id === payload.id,
          )
          if (index !== -1) {
            state.members.datas[index] = payload
          }
        },
      )
  },
})

export const { removeMembers, setSelected } = memberSlice.actions

export default memberSlice.reducer
