import { BarSeries } from '@devexpress/dx-react-chart-material-ui'
import {
  ArgumentAxis,
  Legend,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'

import BaseChart from './BaseChart'

type ListCharProps = {
  title?: string
  data: Array<any>
  valueField: string
  argumentField: string
  name?: string
  toolBar?: any
}

export default function ListChart({
  data,
  valueField,
  argumentField,
  name,
  toolBar,
}: ListCharProps) {
  return (
    <BaseChart data={data} rotated>
      <ArgumentAxis />
      <ValueAxis />
      <BarSeries
        valueField={valueField}
        argumentField={argumentField}
        name={name}
        // color="red"
      />
      {/* {name && <Legend position="top" />} */}
      <Legend
        position="top"
        rootComponent={toolBar}
        // itemComponent={LegendItemRoot}
        // labelComponent={LegendLabelRoot}
        // markerComponent={Marker}
      />
      <Animation />
    </BaseChart>
  )
}
