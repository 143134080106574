import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DoctorDto } from '@services/api'
import { findAll } from '@state/thunks/doctorThunk'

type doctorState = {
  doctors: {
    totalCount: number
    datas: DoctorDto[]
  }
}

const initialState: doctorState = {
  doctors: {
    totalCount: 0,
    datas: [],
  },
}

const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(
      findAll.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.doctors = payload
      },
    ),
})

// eslint-disable-next-line no-empty-pattern
export const {} = doctorSlice.actions

export default doctorSlice.reducer
