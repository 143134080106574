import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  Paper,
  DialogActions,
} from '@mui/material'

import { removeSites } from '@state/reducers/siteReducer'
import { deleteSite, findAll as findAllSites } from '@state/thunks/siteThunk'
import { Actions } from '@state/actions'
import { useSelector } from '@state/store'
import { SiteForm } from './SiteForm'
import { DataTable } from '@components/tables/DataTable'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { DialogTitle } from '@components/titles'
import theme from '@utils/theme'
import { SiteDto } from '../../../services/api'
import { Button } from '../../../components/buttons'
import Text from '@components/Text'

export default function Sites() {
  const dispatch = useDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const _onDeleteConfirm = () => {
    dispatch(deleteSite(selectedRows))
    setIsDeleteDialogOpen(false)
  }

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }
  const { sites, loading } = useSelector(({ site, loading }) => ({
    sites: site.sites.datas,
    loading:
      loading[Actions.SITE_FIND_ALL] ||
      loading[Actions.SITE_CREATE] ||
      loading[Actions.SITE_UPDATE],
  }))

  const columns: GridColumns = [
    { field: 'label', headerName: 'Name', flex: 1 },
    { field: 'finessNumber', headerName: 'FINESS', flex: 1 },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      valueGetter: (params) => {
        const site = params.row as SiteDto
        return `${site.streetAddress} ${site.postalCode}, ${site.city}, ${site.country}`
      },
    },
    {
      field: 'syteType',
      headerName: 'Site Type',
      flex: 1,
      valueGetter: (params) => {
        const site = params.row as SiteDto
        return site.siteType?.label
      },
    },

    {
      field: 'capacity',
      headerName: 'Capacity',
      flex: 1,
      type: 'number',
    },
    {
      field: 'receptions',
      headerName: 'Receptions',
      flex: 1,
      type: 'number',
      valueGetter: (params) => params.row.receptions.length,
    },
    {
      field: 'waitingRooms',
      headerName: 'Waiting Rooms',
      flex: 1,
      type: 'number',
      valueGetter: (params) => params.row.waitingRooms.length,
    },
    {
      field: 'examRooms',
      headerName: 'Exam Rooms',
      flex: 1,
      type: 'number',
      valueGetter: (params) => params.row.examRooms.length,
    },
    {
      field: 'prepRooms',
      headerName: 'Preparation Rooms',
      flex: 1,
      type: 'number',
      valueGetter: (params) => params.row.prepRooms.length,
    },
    {
      field: 'offices',
      headerName: 'Office',
      flex: 1,
      type: 'number',
      valueGetter: ({ value }) => value.length,
    },
  ]

  const onRefresh = useCallback(() => {
    dispatch(findAllSites())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeSites())
    }
  }, [dispatch, onRefresh])

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '90vh', marginTop: '25px' }}>
        <Grid item container>
          <Paper elevation={2} style={{ width: '100%', borderRadius: 25 }}>
            <DataTable
              rows={sites}
              columns={columns}
              getRowId={(row) => row.id}
              loading={loading}
              toolBarProps={{
                title: 'sites',
                icon: 'fa fa-hospital',
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
                onDelete: () => {
                  selectedRows.length
                    ? setIsDeleteDialogOpen(true)
                    : alert('please select at least one room')
                },
              }}
              detailPanelHeight={400}
              form={(props) => (
                <SiteForm {...props} onClose={() => setOpenAddDialog(false)} />
              )}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            borderRadius: 25,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addSite"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent sx={{ background: '#fff', padding: '50px' }}>
          <SiteForm onClose={() => setOpenAddDialog(false)} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="deleteSite"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
    </Container>
  )
}
