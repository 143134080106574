import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  Paper,
  DialogActions,
} from '@mui/material'
import { DataTable } from '@components/tables'
import { useSelector } from '@state/store'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { DialogTitle } from '@components/titles'
import { findAll as findAllSites } from '@state/thunks/siteThunk'
import { Actions } from '@state/actions'
import theme from '@utils/theme'
import { removeSites } from '../../../state/reducers/siteReducer'
import { RoleForm } from './RoleForm'
import { deleteRoles, findAllRoles } from '@state/thunks/roleThunk'
import { Button } from '../../../components/buttons'
import { Text } from '../../../components/texts'

export default function Role() {
  const dispatch = useDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const { loading, roles } = useSelector(({ role, loading }) => ({
    roles: role.roles,
    loading: loading[Actions.ROLE_FIND_ALL] || loading[Actions.ROLE_UPDATE],
  }))

  const columns: GridColumns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
  ]

  const onRefresh = useCallback(() => {
    dispatch(findAllRoles())
    dispatch(findAllSites())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeSites())
    }
  }, [dispatch, onRefresh])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }
  const _onDeleteConfirm = () => {
    dispatch(deleteRoles({ ids: selectedRows }))
    setIsDeleteDialogOpen(false)
  }

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '90vh', marginTop: '25px' }}>
        <Grid item container>
          <Paper elevation={2} style={{ width: '100%', borderRadius: 25 }}>
            <DataTable
              rows={roles}
              columns={columns}
              loading={loading}
              toolBarProps={{
                title: 'roles',
                icon: 'fab fa-uikit',
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
                onDelete: () => {
                  selectedRows.length
                    ? setIsDeleteDialogOpen(true)
                    : alert('please select at least one office')
                },
              }}
              detailPanelHeight={400}
              form={(props) => (
                <RoleForm {...props} onClose={() => setOpenAddDialog(false)} />
              )}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
            />
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            minWidth: '50%',
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addRole"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            padding: '50px',
          }}
        >
          <RoleForm onClose={() => setOpenAddDialog(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="deleteReception"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
    </Container>
  )
}
