import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { TextInput } from '@components/inputs'
import { Button } from '@components/buttons'

import { AppDispatch, useSelector } from '@state/store'
import { forgottenPassowrdSchema } from '@utils/schemas'

import { AuthCard } from '../../components/cards/AuthCard'
import { forgottenPassword } from '@state/thunks/authThunk'
import { useNavigate } from 'react-router-dom'
import { Actions } from '@state/actions'

type Values = {
  email: string
}

export default function ForgottenPassword() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const loading = useSelector(
    ({ loading }) => loading[Actions.FORGOTTEN_PASSWORD],
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    resolver: yupResolver(forgottenPassowrdSchema),
  })

  const onSubmit = ({ email }: Values) => {
    dispatch(forgottenPassword({ email })).then(() => navigate('/auth/login'))
  }

  const Content = (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12} sm={12}>
        <TextInput
          variant="filled"
          type="email"
          name="email" // {...register('email')}
          label="Email"
          color="primary"
          errors={errors}
          control={control}
          autoFocus
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button
          type="submit"
          text="submit"
          loading={loading}
          fullWidth
          color="secondary"
          format
        />
      </Grid>
    </Grid>
  )

  return (
    <AuthCard>
      <AuthCard.Header title="forgotten-password" />
      <AuthCard.Content>{Content}</AuthCard.Content>
      <AuthCard.Actions backToLoginLink />
    </AuthCard>
  )
}
