import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SiteDto, SiteTypeDto } from '@services/api'
import {
  createSite,
  deleteSite,
  findAll,
  findAllSite,
  updateSite,
} from '@state/thunks/siteThunk'

type siteState = {
  sites: {
    totalCount: number
    datas: SiteDto[]
  }
  selected?: SiteDto
  siteTypes: SiteTypeDto[]
}

const initialState: siteState = {
  sites: {
    totalCount: 0,
    datas: [],
  },
  siteTypes: [],
}

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    removeSites: (state) => {
      state.sites = initialState.sites
    },
    setSelected: (state, action: PayloadAction<SiteDto>) => {
      state.selected = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAll.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.sites = payload
      })
      .addCase(
        createSite.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.sites.datas = [...state.sites.datas, payload]
          state.sites.totalCount++
        },
      )
      .addCase(
        deleteSite.fulfilled,
        (state, { payload }: PayloadAction<number[]>) => {
          state.sites.datas = state.sites.datas.filter(
            (_site) => !payload.includes(_site.id),
          )
          state.sites.totalCount--
        },
      )
      .addCase(
        findAllSite.fulfilled,
        (state, { payload }: PayloadAction<SiteTypeDto[]>) => {
          state.siteTypes = payload
        },
      )
      .addCase(
        updateSite.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.sites.datas.findIndex(
            (site) => site.id === payload.id,
          )
          if (index === -1) {
            return
          }

          state.sites.datas[index] = payload
        },
      )
  },
})

export const { removeSites, setSelected } = siteSlice.actions

export default siteSlice.reducer
