import { Add, Refresh, ViewColumn, Delete } from '@mui/icons-material'
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro'
import Text from '../../Text'
import { useIntl } from 'react-intl'
import {
  Tooltip,
  IconButton,
  Icon,
  Box,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel,
} from '@mui/material'
import { useState } from 'react'

export type ToolbarProps = {
  title: string
  icon: string
  onRefresh: () => void
  onAdd?: () => void
  onDelete?: () => void
}

export function Toolbar({
  title,
  icon,
  onRefresh,
  onAdd,
  onDelete,
}: ToolbarProps) {
  const apiRef = useGridApiContext()

  const intl = useIntl()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const columns = apiRef.current.getAllColumns()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleHide = (event: React.MouseEvent<any>) => {
    const column = columns.find(
      (column) => column.field == event.currentTarget.id,
    )
    if (column) apiRef.current.setColumnVisibility(column.field, !!column.hide)
  }
  return (
    <GridToolbarContainer>
      <Box pl={2} display="flex" alignItems="center" justifyContent="center">
        <Icon
          className={icon}
          style={{ marginRight: '1rem' }}
          color="primary"
        />
        <Text
          variant="h5"
          text={title}
          color="primary"
          format
          sx={{ fontWeight: 'bold' }}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {/* <GridToolbarDensitySelector /> */}
      {/* <GridToolbarExport /> */}
      {/* <GridToolbarFilterButton /> */}
      <Tooltip title={intl.formatMessage({ id: 'column' })}>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <ViewColumn />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {columns.map((column, key) => (
          <MenuItem key={key} onClick={handleHide} id={column.field}>
            <FormControlLabel
              control={<Checkbox checked={!column.hide} />}
              label={column.headerName as string}
            />
          </MenuItem>
        ))}
      </Menu>
      <Tooltip title={intl.formatMessage({ id: 'add' })}>
        <IconButton onClick={onAdd}>
          <Add />
        </IconButton>
      </Tooltip>
      {onDelete && (
        <Tooltip title={intl.formatMessage({ id: 'delete' })}>
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={intl.formatMessage({ id: 'refresh' })}>
        <IconButton onClick={onRefresh}>
          <Refresh />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  )
}
