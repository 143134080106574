import {
  ArgumentAxis,
  Legend,
  LineSeries,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'

import BaseChart from './BaseChart'

type LineSimpleProps = {
  title?: string
  data: Array<any>
  valueField: string
  argumentField: string
  toolBar?: any
}

export default function LineSimple({
  data,
  valueField,
  argumentField,
  toolBar,
}: LineSimpleProps) {
  return (
    <BaseChart data={data}>
      <ArgumentAxis
      // tickFormat={format}
      />
      <ValueAxis

      //  labelComponent={'cool'}
      />

      <LineSeries
        //name="TV news"
        valueField={valueField}
        argumentField={argumentField}
      />
      <Legend
        position="top"
        rootComponent={toolBar}
        // itemComponent={LegendItemRoot}
        // labelComponent={LegendLabelRoot}
        // markerComponent={Marker}
      />
      <Animation />
    </BaseChart>
  )
}
