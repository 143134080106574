import { useEffect, useState } from 'react'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from '@mui/material'
import { Button } from '@components/buttons'

import Text from '../Text'

type AddClientDialogProps = {
  onClose: () => void
  open: boolean
  handleLogout: () => void
  handleStayLogged: () => void
}

export function AutoLogoutDialog({
  onClose,
  open,
  handleLogout,
  handleStayLogged,
}: AddClientDialogProps) {
  const modalTime = 10
  const [time, setTime] = useState(modalTime)

  useEffect(() => {
    if (open) {
      if (time === 0) handleLogout()
      const interval = setInterval(() => setTime(time - 1), 1000)
      return () => clearInterval(interval)
    } else {
      setTime(modalTime)
    }
  }, [handleLogout, open, time])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    >
      <DialogContent>
        <Box px={5} py={5} textAlign="center">
          <Text
            text={`You will be discconnected in ${time} s because of inactivity`}
          />
          <Text text={' Do you want to stay connected ?'} />
        </Box>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Grid container justifyContent="space-around">
          <Button
            format
            text="forms.buttons.no"
            onClick={handleLogout}
            color="secondary"
          />
          <Button
            format
            text="forms.buttons.yes"
            onClick={handleStayLogged}
            color="primary"
          />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
