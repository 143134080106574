import { Grid } from '@mui/material'
import Stats from '../stat/Stats'

export const memberLinks = [
  {
    to: '/m/',
    title: 'Home',
    icon: 'fa fa-home',
  },
  {
    to: '/m/preferences',
    title: 'Preferences',
    icon: 'fas fa-cogs',
  },
  {
    to: '/m/sites',
    title: 'Sites',
    icon: 'fa fa-hospital',
  },
  {
    to: '/m/rooms',
    title: 'Rooms',
    icon: 'fab fa-uikit',
  },
  {
    to: '/m/members',
    title: 'Member',
    icon: 'fa fa-user',
  },
  {
    to: '/m/roles',
    title: 'Roles',
    icon: 'fas fa-user-lock',
  },
  {
    to: '/m/procedures',
    title: 'Procedures',
    icon: 'fa fa-syringe',
  },
  {
    to: '/m/questions',
    title: 'Questions',
    icon: 'fab fa-wpforms',
  },
  {
    to: '/m/equipment',
    title: 'Technical platform',
    icon: 'fas fa-tools',
  },
  // {
  //   to: '/m/logs',
  //   title: 'Logs',
  //   icon: 'fa fa-database',
  // },
]

export default function HomeMember() {
  return (
    <Grid container spacing={2} sx={{ marginTop: '80px' }}>
      <Grid item xs={12} sm={12}>
        <Stats />
      </Grid>
    </Grid>
  )
}
