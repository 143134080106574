import { useState } from 'react'

import { Box } from '@mui/material'

import EcSelect from '@components/inputs/EcSelect'
import { SpaceHorizontal } from '@utils/Spacing'
import {
  chartActivitySelect,
  chartSelectTop_10_100,
  medicalEquipmentType,
  medicalImagingSpecialities,
} from '@utils/constants'
import BarSimple from '@components/charts/BarSimple'
import ListChart from '@components/charts/ListChart'
import Pie from '@components/charts/Pie'

interface IDataItem {
  year: string
  hl7Number: number
}

const dataReceived: IDataItem[] = [
  { year: '2017', hl7Number: 10 },
  { year: '2018', hl7Number: 4 },
  { year: '2019', hl7Number: 5 },
  { year: '2020', hl7Number: 15 },
  { year: '2021', hl7Number: 5 },
  { year: '2022', hl7Number: 8 },
  { year: '2023', hl7Number: 6 },
]

function getLabelWithValue(value: unknown, ArraySelect: Array<any>): string {
  return ArraySelect[(value as number) - 1].label
}

export default function ActivityChart() {
  const [dataChart] = useState<Array<any>>(dataReceived)
  const [typeChart, setTypeChart] = useState('1')

  const ToolBar = () => (
    <Box mb={1.5} display="flex">
      <EcSelect
        label="Chart"
        options={chartActivitySelect}
        handleSelected={(option: string) => {
          setTypeChart(option)
        }}
      />
      {typeChart === '1' ? (
        <>
          <SpaceHorizontal />
          <EcSelect label="Modality Type" options={medicalEquipmentType} />
        </>
      ) : typeChart === '2' || typeChart === '3' ? (
        <>
          <SpaceHorizontal />
          <EcSelect label="Filter" options={chartSelectTop_10_100} />
        </>
      ) : typeChart === '4' || typeChart === '5' || typeChart === '6' ? (
        <>
          <SpaceHorizontal />
          <EcSelect label="Type Imaging" options={medicalImagingSpecialities} />
        </>
      ) : null}

      <SpaceHorizontal />
      <EcSelect
        label="Period"
        options={[
          { value: 1, label: 'Day' },
          { value: 2, label: 'Week' },
          { value: 3, label: 'Month' },
          { value: 4, label: 'Start/End' },
        ]}
      />
    </Box>
  )

  return (
    <>
      {typeChart === '1' || typeChart === '7' ? (
        <BarSimple
          data={dataChart}
          valueField="hl7Number"
          argumentField="year"
          name={
            getLabelWithValue(typeChart, chartActivitySelect).toString() || ''
          }
          toolBar={ToolBar}
        />
      ) : typeChart === '2' || typeChart === '3' ? (
        <ListChart
          data={dataChart}
          valueField="hl7Number"
          argumentField="year"
          name={
            getLabelWithValue(typeChart, chartActivitySelect).toString() || ''
          }
          toolBar={ToolBar}
        />
      ) : typeChart === '4' || typeChart === '5' || typeChart === '6' ? (
        <Pie
          data={dataChart}
          valueField="hl7Number"
          argumentField="year"
          toolBar={ToolBar}
        />
      ) : null}
    </>
  )
}
