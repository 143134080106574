import { useMemo, forwardRef } from 'react'
import { LinkProps, Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import {
  Icon,
  IconProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
} from '@mui/material'

interface ListItemLinkProps {
  IconProps: IconProps
  ItemTextProps?: ListItemTextProps
  text: string
  to: string
  format?: boolean
}

export function ListItemLink({
  text,
  to,
  IconProps,
  ItemTextProps,
  format = false,
}: ListItemLinkProps) {
  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to],
  )

  return (
    <ListItem button component={renderLink} sx={{ color: '#fff' }}>
      <ListItemIcon style={{ minWidth: 40 }}>
        <Icon
          {...IconProps}
          fontSize="medium"
          sx={{ color: '#fff', paddingRight: '28px' }}
        />
      </ListItemIcon>
      <ListItemText
        {...ItemTextProps}
        primary={format ? <FormattedMessage id={text} /> : text}
      />
    </ListItem>
  )
}
