import { useState } from 'react'

import { Box } from '@mui/material'

import EcSelect from '@components/inputs/EcSelect'
import { SpaceHorizontal } from '@utils/Spacing'
import { chartSecuritySelect, chartSelectTop_20_100 } from '@utils/constants'
import BarSimple from '@components/charts/BarSimple'
import ListChart from '@components/charts/ListChart'
import Pie from '@components/charts/Pie'

interface IDataItem {
  year: string
  hl7Number: number
}

const dataReceived: IDataItem[] = [
  { year: '2017', hl7Number: 10 },
  { year: '2018', hl7Number: 4 },
  { year: '2019', hl7Number: 5 },
  { year: '2020', hl7Number: 15 },
  { year: '2021', hl7Number: 5 },
  { year: '2022', hl7Number: 8 },
  { year: '2023', hl7Number: 6 },
]

// const dataSent: IDataItem[] = [
//   { year: '2010', hl7Number: 1 },
//   { year: '2011', hl7Number: 17 },
//   { year: '2012', hl7Number: 2 },
//   { year: '2013', hl7Number: 5 },
//   { year: '2014', hl7Number: 12 },
//   { year: '2015', hl7Number: 7 },
//   { year: '2016', hl7Number: 3 },
// ]

// const useStyles = makeStyles((theme) => ({

// }))

// const fields = [
//   {
//     name: 'hl7 Received',
//     valueField: 'received',
//     argumentField: 'date',
//   },

//   {
//     name: 'hl7 Sent',
//     valueField: 'sent',
//     argumentField: 'date',
//   },
// ]
function getLabelWithValue(value: unknown, ArraySelect: Array<any>): string {
  return ArraySelect[(value as number) - 1].label
}

export default function SecurityChart() {
  // const classes = useStyles()

  // const [dataChart, setDataChart] = React.useState<Array<any>>(dataReceived)
  const [typeChart, setTypeChart] = useState('1')

  const ToolBar = () => (
    <Box mb={1.5} display="flex">
      <EcSelect
        label="Chart"
        options={chartSecuritySelect}
        handleSelected={(option: string) => {
          setTypeChart(option)
        }}
      />
      <SpaceHorizontal />
      <EcSelect label="Filter" options={chartSelectTop_20_100} />
      <SpaceHorizontal />
      <EcSelect
        label="Period"
        options={[
          { value: 1, label: 'Day' },
          { value: 2, label: 'Week' },
          { value: 3, label: 'Month' },
          { value: 4, label: 'Start/End' },
        ]}
      />
    </Box>
  )

  return (
    <>
      {typeChart === '3' ? (
        <BarSimple
          data={dataReceived}
          //list={(typeChart === '2' || typeChart === '3') ? true : false}
          valueField="hl7Number"
          argumentField="year"
          // height={385}
          name={
            getLabelWithValue(typeChart, chartSecuritySelect).toString() || ''
          }
          toolBar={ToolBar}
        />
      ) : typeChart === '1' || typeChart === '2' ? (
        <ListChart
          data={dataReceived}
          //list={(typeChart === '2' || typeChart === '3') ? true : false}
          valueField="hl7Number"
          argumentField="year"
          // height={385}
          name={
            getLabelWithValue(typeChart, chartSecuritySelect).toString() || ''
          }
          toolBar={ToolBar}
        />
      ) : typeChart === '4' ? (
        <Pie
          data={dataReceived}
          valueField="hl7Number"
          argumentField="year"
          // height={320}
          toolBar={ToolBar}
        />
      ) : null}
    </>
  )
}
