import { IntlProvider } from 'react-intl'

import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'
import { locales } from './locales'
import Notifier from './containers/Notifier'
import Router from './containers/Router'
import { useSelector } from '@state/store'

export default function App() {
  const { language } = useSelector(({ auth }) => ({
    language: auth.language,
  }))
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <IntlProvider locale={language} messages={locales(language)}>
        <SnackbarProvider>
          <Router />
          <Notifier />
          {/* <AutoLogout /> */}
          {/* <AutoLogoutAlert /> */}
        </SnackbarProvider>
      </IntlProvider>
    </LocalizationProvider>
  )
}
