import { Paper } from '@mui/material'
import { Chart, ChartProps } from '@devexpress/dx-react-chart-material-ui'

type BaseChartProps = ChartProps & {
  children: any
}

export default function BaseChart({ ...props }: BaseChartProps) {
  return (
    <Paper elevation={2}>
      <Chart {...props} />
    </Paper>
  )
}
